<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flexspabet" @keyup.enter.native="$refs.table.reload()"
        @submit.native.prevent>
        <div>
          <el-button @click="(showEdit=true) && (showEditData = false)" size="small"
            v-if="permission.includes('sys:sys_message:add')" class="ele-btn-icon addbtn ">添加
          </el-button>
        </div>
        <div class='d-flex'>
          <el-form-item label="消息类型:" label-width="88px" class="w-250">
            <el-select v-model="table.where.child_type" placeholder='请选择类型' clearable>
              <el-option label="弹窗消息" value="e1" />
              <el-option label="app消息" value="a4" />
              <el-option label="活动公告" value="b1" />
              <el-option label="平台消息" value="g1" />
            </el-select>
          </el-form-item>



          <el-form-item>
            <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon ml-20">搜索
            </el-button>
            <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" stripe class="my-dialog-name">
        <template slot-scope="{index}">
          <el-table-column type="index" :index="index" label="编号" width="60px" align="center" show-overflow-tooltip />
          <el-table-column prop="realname" label="发送人" show-overflow-tooltip width="100px" />
          <el-table-column prop="title" label="标题" show-overflow-tooltip min-width="160" />
          <el-table-column prop="cn_type" label="消息类型" show-overflow-tooltip width="140px" />
          <el-table-column prop="cn_scope" label="接收端" show-overflow-tooltip width="140px" />
          <el-table-column prop="cn_create_time" label="日期" show-overflow-tooltip width="140px" />


          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:sys_message:edit')">查看</el-link>
                
       
               <el-link  icon="el-icon-delete" type="danger" :underline="false" @click="remove(row)">删除</el-link>
           
             
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>

    <!-- 添加弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'查看系统消息':'添加消息'" :visible.sync="showEdit" @closed="editForm={}"
      :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
          <el-form-item label="消息标题：" prop="title">
            <el-input type="text" placeholder="请输入内容" v-model="editForm.title" class="input216"> </el-input>
          </el-form-item>

          <el-form-item label="消息类型：" prop="child_type" >
            <el-select v-model="editForm.child_type" :disabled="editForm.id && showEditData == false?true:false">
              <el-option label="弹窗消息" value="e1" />
              <el-option label="app消息" value="a4" />
              <el-option label="活动公告" value="b1" />
              <el-option label="平台消息" value="g1" />
            </el-select>
          </el-form-item>

          <el-form-item class="block" label="有效时间：" v-if="editForm.child_type == 'e1'">

            <el-date-picker v-model="timeVal" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
              start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['12:00:00']">
            </el-date-picker>
          </el-form-item>

          <el-form-item v-if="editForm.child_type != 'g1'" label="接收端：">
            <el-select v-model="editForm.scope" placeholder="请选择" :disabled="editForm.id && showEditData == false?true:false">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>


          </el-form-item>



          <el-form-item label="地区：" prop="pid">
            <el-select v-model="editForm.pname" @change="handleChangeProv2(editForm.pname)" placeholder="请选择省"
              class="selectStyle mr-10 mb-20" clearable :disabled="showEditData == true">
              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
              </el-option>
            </el-select>
            <el-select v-model="editForm.cname" @change="handleChangeCity2(editForm.cname)" placeholder="请选择市"
              class="selectStyle mr-10 mb-20" clearable :disabled="showEditData == true">
              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
              </el-option>
            </el-select>
            <el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)" placeholder="请选择区/县"
              class="selectStyle mr-10 mb-20" clearable :disabled="showEditData == true">
              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name">
              </el-option>
            </el-select>
          </el-form-item>




          <el-form-item label="简介：" prop="brief">
            <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="editForm.brief"> </el-input>
          </el-form-item>
          <el-form-item label="发布内容：" prop="content" v-if="editForm.side_type != 6">
            <!--:init="editContent"-->
            <tinymce-editor v-model="editForm.content" />
          </el-form-item>
           
          <el-form-item label="链接：">
            <el-input type="text" v-model="editForm.link_url" class="input216">
            </el-input>
          </el-form-item>
          
          <el-form-item label="消息状态：">
           <el-switch v-model="switchVal"></el-switch>

           
           
          </el-form-item>
          
          
          
          
          
        </el-form>
      </el-card>
      <div slot="footer" v-if="!editForm.id">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">发布</el-button>
      </div>
      <div slot="footer" v-if="editForm.id">
        <el-button @click="(showEdit=false) && (showEditData=false)">取消</el-button>
        <el-button type="primary" @click="update">发布</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  import TinymceEditor from '@/components/TinymceEditor'
  export default {
    name: "SysCountyCircle",
   
    components: {
      TinymceEditor
    },
    data() {
       var checktitle = (rule, value, callback) => {
              var reg = new RegExp("^[\u4e00-\u9fa5|0-9|A-Z|a-z|,|:|~|.|，|。|、|~]{1,16}$", "g");
              if (!reg.test(value)) {
                callback(new Error('请输入标题,不得多于16字'));
              } else {
                callback();
              }
            };
            var checktxt = (rule, value, callback) => {
              var reg = new RegExp("^[\u4e00-\u9fa5|0-9|A-Z|a-z|,|:|~|.|，|。|、|~]{1,100}$", "g");
              if (!reg.test(value)) {
                callback(new Error('请输入内容,不得多于100字'));
              } else {
                callback();
              }
            };
      return {
        switchVal:true,
        ishas:false,
        tbData:[],
        timeVal: [],
        table: {
          url: '/message/msgInfoListSys',
          where: {}
        }, // 表格配置
        provArr: [],
        cityArr: [],
        districtArr: [],
        choose: [], // 表格选中数据
        showEdit: false, // 是否显示表单弹窗
        showEditData: false, // 编辑内容信息
        editForm: {}, // 表单数据
        userInfo: {},
        options: [{
          value: "abc",
          label: '全平台'
        }, {
          value: "b",
          label: '可蚁点司机'
        }, {
          value: "a",
          label: '可蚁点用户'
        }, {
          value: "c",
          label: '可蚁点小程序'
        }, {
          value: "ab",
          label: '用户/司机'
        }, {
          value: "ac",
          label: '用户/小程序'
        }],
        editRules: { // 表单验证规则
          title: [
            {
              validator:checktitle,trigger:'blur'
            }
          ],
          child_type: [{
            required: true,
            message: '请选择消息类型',
            trigger: "change"
          }],
          scope: [{
            required: true,
            message: '请选择接收端',
            trigger: "change"
          }],
          brief: [
            {validator:checktxt,trigger:'blur'}
          ]

        },
      }
    },
    created() {
      // this.$http.get('/message/sideTypeList').then(res => {
      // 	let data = JSON.parse(res.data)
      // 	this.provArr = data
      // })


      this.$http.get('/common/province_list').then(res => {
        let data = JSON.parse(res.data)
        this.provArr = data
      })

         


    },
    
    computed: {
      ...mapGetters(["permission"]),
    },
    mounted() {
      this.getListdata();
      this.getUserInfo();
    },
    methods: {
      getListdata(){
        this.$http.post('/message/msgInfoList').then(res => {
        	  //console.log('tttttttttii8888888888',res.data);
            
             this.ishas=true;
        })
        
      },
      /* 获取当前用户信息 */
      getUserInfo() {
        this.$http.get(this.$setting.userUrl).then(res => {
          if (res.data.code === 0) {
            var info = JSON.parse(JSON.stringify(res.data.data))
            this.userInfo = info
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          this.$message.error(e.message);
        })
      },
      /**
       *选择省
       **/
      handleChangeProv(e) {
        /** 获取被选省份的pid**/
        let pid = ''
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.table.where.cid = ''
          this.table.where.area_id = ''
        })
      },
      /**
       *选择市
       **/
      handleChangeCity(e) {
        if (e) {
          /** 获取被选市的cid**/
          let cid = ''
          this.cityArr.forEach(function(item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
            //console.log('9900---thisdistrictArr', this.districtArr);
            /** 选择省份清空县**/
            this.table.where.aid = ''
          })
        } else {
          this.table.where.pid = ''
          this.table.where.aid = ''
        }
      },
      
      /* 删除 */
      remove(row) {
          let that=this;
          
          const loading = this.$loading({lock: true});
          this.$http.post('/message/msgDelete', {id:row.id}).then(res => {
             loading.close();

            if (res.data.code == 1) {
             this.$message({type: 'success', message: res.data.msg});
             this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
           
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        
      },


      



      /**
       *修改省
       **/
      handleChangeProv1(e) {
        /** 获取被选省份的pid**/
        var pid = ''
        this.provArr.forEach(function(item) {
          if (item.name == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          var data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      },
      /**
       *修改市
       **/
      handleChangeCity1(e) {
        if (e) {
          /** 获取被选市的cid**/
          var cid = ''
          this.cityArr.forEach(function(item) {
            if (item.name == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            var data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.editForm.aname = ''
          })
        } else {
          this.editForm.pname = ''
          this.editForm.aname = ''
        }
      },
      /**
       * 显示编辑
       */
      edit(row, edit) {
        this.editForm = row;
        //console.log('sel------data',this.editForm);
        if (this.editForm.pid) {
          this.editForm.pname = this.editForm.pid
          // this.handleChangeProv(this.editForm.pname);
        }
        if (this.editForm.cid) {
          this.editForm.cname = this.editForm.cid
          // this.handleChangeCity(this.editForm.cname);
        }
        if (this.editForm.aid) {
          this.editForm.aname = this.editForm.aid
        }
        if (this.editForm.pid != '') {
          this.$http.post('/common/city_list', {
            pid: this.editForm.pid
          }).then(res => {
            let cdata = JSON.parse(res.data)
            this.cityArr = cdata
          })
        }
        if (this.editForm.cid != '') {
          this.$http.post('/common/area_list', {
            cid: this.editForm.cid
          }).then(res => {
            let adata = JSON.parse(res.data)
            this.districtArr = adata
          })
        }
        if (this.editForm.cn_start_time) {
        
          this.timeVal[0] = this.editForm.cn_start_time;
          this.timeVal[1] = this.editForm.cn_expires_time;

        }
        this.switchVal=this.editForm.status==1?true:false;
        
     
        const loading = this.$loading({
          lock: true
        });
        if (this.editForm) {
          this.showEdit = true;
          this.showEditData = false;
          if (edit && edit == 1) {
            this.showEditData = true;
          }
          loading.close();
        }
      },
      /**
       * 保存编辑
       */
      save() {
        
        this.$refs['editForm'].validate((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true
            });

            if (this.editForm.pname) {
              this.editForm.pid = this.editForm.pname
            }
            if (this.editForm.cname) {
              this.editForm.cid = this.editForm.cname
            }
            if (this.editForm.aname) {
              this.editForm.aid = this.editForm.aname
            }
            if (this.timeVal.length == 2) {
              this.editForm.start_time = this.timeVal[0]
              this.editForm.expires_time = this.timeVal[1]
            }
            
            this.editForm.status = this.switchVal?1:0;
             this.$http.post('/message/msgAdd', this.editForm).then(res => {
              loading.close();
              //console.log('66677775555', res);

              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                });
                this.$refs.table.reload();
                this.showEdit = false;
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(e => {
              loading.close();
              this.$message.error(e.message);
            });
          } else {
            return false;
          }
        });
      },
      update() {
        
        if (this.editForm.pname) {
          this.editForm.pid = this.editForm.pname
        }
        if (this.editForm.cname) {
          this.editForm.cid = this.editForm.cname
        }
        if (this.editForm.aname) {
          this.editForm.aid = this.editForm.aname
        }
        if (this.timeVal.length == 2) {
          this.editForm.start_time = this.timeVal[0]
          this.editForm.expires_time = this.timeVal[1]
        }
        
         this.editForm.status = this.switchVal?1:0;
        
        this.$refs['editForm'].validate((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true
            });

            this.$http.post('/message/msgUpdate', this.editForm).then(res => {
              loading.close();
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                });
                this.$refs.table.reload();
                this.showEdit = false;
              } else {
                this.$message.error(res.data.msg);
              }
            }).catch(e => {
              loading.close();
              this.$message.error(e.message);
            });
          } else {
            return false;
          }
        });
      },

      handleChangeProv2(e) {
        /** 获取被选省省份的pid**/
        this.changePro = true
        let pid = ''
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      },
      /**
       *选择市
       **/
      handleChangeCity2(e) {
        if (e) {
          /** 获取被选市的cid**/
          this.changePro = true
          let cid = ''
          this.cityArr.forEach(function(item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.editForm.aname = ''
          })
        } else {
          this.editForm.pname = ''
          this.editForm.aname = ''
        }
      },
      handleDis() {
        this.changePro = true
      },
    }
  }
</script>

<style scoped>
  .ele-block>>>.el-upload,
  .ele-block>>>.el-upload-dragger {
    width: 100%;
  }

  /deep/.el-dialog {
    margin-top: 30px !important;
  }
</style>
